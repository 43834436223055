/** @jsx jsx */
import React from 'react';
import { jsx, Flex } from 'theme-ui';
import { graphql, StaticQuery } from 'gatsby';
import ProductsListingItem from './ProductsListingItem';

const PRODUCTS_LISTING_QUERY = graphql`
  query ProductsListingQuery {
    products: allShopifyProduct(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          title
          id
          handle
          description
          productType
          availableForSale
          priceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          variants {
            priceV2 {
              amount
              currencyCode
            }
            compareAtPriceV2 {
              amount
            }
            sku
            availableForSale
          }
          tags
          images {
            id
            localFile {
              childImageSharp {
                gatsbyImageData(maxWidth: 400, layout: FLUID, formats: [WEBP])
              }
            }
          }
        }
      }
    }
  }
`;

const ProductsListing = () => {
  return (
    <div>
      <StaticQuery
        query={PRODUCTS_LISTING_QUERY}
        render={({ products }) => {
          return (
            <Flex
              sx={{
                flexWrap: 'wrap',
                justifyContent: 'space-around',
              }}
            >
              {products.edges.map(({ node: product }, index) => (
                <ProductsListingItem
                  key={product.id}
                  index={index}
                  product={product}
                />
              ))}
            </Flex>
          );
        }}
      />
    </div>
  );
};

export default ProductsListing;
