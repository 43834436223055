/** @jsx jsx */
import React, { useState } from 'react';
import { Link } from 'gatsby';
import { jsx, Box } from 'theme-ui';
import { GatsbyImage } from 'gatsby-plugin-image';
import { formatter } from '../../utils';

const ProductsListingItem = ({ product, index }) => {
  const [isHovered, setIsHovered] = useState(false);

  const {
    images: [firstImage, secondImage],
    priceRange: {
      maxVariantPrice: { amount: maxPrice },
    },
    priceRange: {
      minVariantPrice: { amount: minPrice },
    },
    variants: [firstVariant],
  } = product;

  const setHover = hover => {
    setIsHovered(hover);
  };

  return (
    <Box
      sx={{
        width: ['100%', '30%'],
        textAlign: 'center',
        pb: 4,
      }}
    >
      <Link
        to={`/product/${product.handle}`}
        sx={{
          fontFamily: 'body',
          color: 'text',
          textDecoration: product.availableForSale ? 'none' : 'line-through',
        }}
      >
        <div
          onMouseOver={() => setHover(true)}
          onFocus={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <GatsbyImage
            image={firstImage.localFile.childImageSharp.gatsbyImageData}
            style={{ display: isHovered ? 'none' : null }}
            sx={{
              opacity: !product.availableForSale && '0.5',
            }}
            // eslint-disable-next-line react/jsx-boolean-value
            loading={index === 0 ? 'eager' : 'lazy'}
            alt={product.title}
          />
          <GatsbyImage
            image={secondImage.localFile.childImageSharp.gatsbyImageData}
            style={{ display: !isHovered ? 'none' : null }}
            sx={{
              opacity: !product.availableForSale && '0.5',
            }}
            alt={product.title}
          />
        </div>

        <h3 sx={{ variant: 'styles.h1' }}>
          {product.tags.includes('pre-order') && 'pre-order - '}
          {product.title}
        </h3>
        {firstVariant.compareAtPriceV2 ? (
          <p sx={{ variant: 'styles.p' }}>
            <span sx={{ textDecoration: 'line-through' }}>
              {formatter.format(firstVariant.compareAtPriceV2.amount)}
            </span>
            <span> - </span>
            <span>{formatter.format(firstVariant.priceV2.amount)}</span>
          </p>
        ) : (
          <p sx={{ variant: 'styles.p' }}>
            {formatter.format(firstVariant.priceV2.amount)}
          </p>
        )}
        {/* <p sx={{ variant: 'styles.p' }}>
          {minPrice < maxPrice ? 'From' : ''} {formatter.format(minPrice)}
        </p> */}
      </Link>
    </Box>
  );
};

export default ProductsListingItem;
