import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/seo/seo';
import ProductsListing from '../components/ProductsListing/ProductsListing';
import EmailSignUp from '../components/EmailSignUp';
import LatestPosts from '../components/LatestPosts';

const IndexPage = () => (
  <Layout>
    <SEO pathname="/" />
    <ProductsListing />
    <hr />
    <LatestPosts />
    <hr />
    <EmailSignUp />
  </Layout>
);

export default IndexPage;
