/** @jsx jsx */
import React, { useState } from 'react';
import { jsx, Flex } from 'theme-ui';
import axios from 'axios';

const EmailSignUp = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleEmailSubmit = e => {
    e.preventDefault();
    axios
      .post('/api/subscribe', {
        email,
      })
      .then(response => {
        setError('');
        setEmail('');
        setSuccess(true);
      })
      .catch(err => {
        setEmail('');
        if (err.response) {
          setError(err.response.data);
          // console.log(error.response.data);
          // console.log(error.response.status);
        }
      });
  };

  const handleInputChange = e => {
    setEmail(e.target.value);
  };

  return (
    <div sx={{ padding: '30px 0px' }}>
      {success ? (
        <h3 sx={{ textAlign: 'center', variant: 'styles.h3' }}>Welcome!</h3>
      ) : (
        <>
          <h3
            sx={{
              textAlign: 'center',
              variant: 'styles.h3',
              marginBottom: '20px',
            }}
          >
            Join the DVLPR family.
          </h3>
          <form
            sx={{ display: 'flex', flexWrap: 'wrap' }}
            onSubmit={handleEmailSubmit}
          >
            <input
              sx={{
                flex: '3 1 30ch',
                margin: '.5rem',
                border: '2px solid',
                borderColor: 'text',
                backgroundColor: 'background',
                padding: '.75rem 1rem',
                color: 'text',
                borderRadius: 0,
              }}
              placeholder="Email Address"
              onChange={handleInputChange}
              value={email}
              type="email"
            />
            <input
              sx={{
                flex: '1 1 10ch',
                margin: '.5rem',
                border: '2px solid',
                borderColor: 'primary',
                padding: '.75rem 1rem',
                variant: 'buttons.solid',
                borderRadius: 0,
              }}
              type="submit"
              value="Subscribe"
            />
          </form>
        </>
      )}

      {error && (
        <p sx={{ color: 'red', fontSize: '12px', marginLeft: '.5rem' }}>
          {error}
        </p>
      )}
    </div>
  );
};

export default EmailSignUp;
