/** @jsx jsx */
import React from 'react';
import { Flex, jsx } from 'theme-ui';
import { graphql, StaticQuery, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const LATEST_POSTS_QUERY = graphql`
  query LatestPostsQuery {
    posts: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 2
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            path
            alt
            image {
              childImageSharp {
                gatsbyImageData(
                  maxWidth: 400
                  maxHeight: 400
                  transformOptions: { grayscale: true }
                  layout: FLUID
                )
              }
            }
          }
        }
      }
    }
  }
`;

const LatestPosts = () => {
  return (
    <StaticQuery
      query={LATEST_POSTS_QUERY}
      render={({ posts }) => {
        return (
          <div sx={{ pt: 4, pb: 5 }}>
            <Flex
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 3,
              }}
            >
              <h2 sx={{ textTransform: 'uppercase' }}>Latest Posts</h2>
              <Link
                to="/blog"
                sx={{
                  textTransform: 'uppercase',
                  textDecoration: 'none',
                  color: 'primary',
                }}
              >
                All Posts
              </Link>
            </Flex>
            <section
              itemScope
              itemType="http://schema.org/Blog"
              sx={{
                display: 'grid',
                gridTemplateColumns: ['100%', 'repeat(2, 1fr)'],
                gridColumnGap: '100px',
                gridRowGap: '100px',
              }}
            >
              {posts.edges.map(({ node }) => {
                const { title } = node.frontmatter;
                return (
                  <Link
                    sx={{
                      width: ['100%'],
                      // marginBottom: [5, 6],
                      justifySelf: 'center',
                      textDecoration: 'none',
                      color: 'primary',
                    }}
                    to={`/blog${node.frontmatter.path}`}
                    key={node.fields.slug}
                  >
                    <article sx={{ border: '1px solid', borderColor: 'text' }}>
                      <header>
                        <h1 sx={{ variant: 'styles.h1', marginLeft: 2 }}>
                          {title}
                        </h1>
                      </header>
                      <section
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                        }}
                      >
                        <p
                          sx={{
                            writingMode: 'vertical-rl',
                            margin: 0,
                            textTransform: 'uppercase',
                            marginBottom: 2,
                            fontSize: 1,
                            fontFamily: 'body',
                            marginLeft: 1,
                            color: 'text',
                          }}
                        >
                          {node.frontmatter.date}
                        </p>
                        <GatsbyImage
                          image={
                            node.frontmatter.image.childImageSharp
                              .gatsbyImageData
                          }
                          sx={{
                            boxShadow: '12px 12px 0 0',
                            color: 'primary',
                            height: 300,
                            width: 230,
                          }}
                          alt={node.frontmatter.alt}
                        />
                      </section>
                    </article>
                  </Link>
                );
              })}
            </section>
          </div>
        );
      }}
    />
  );
};

export default LatestPosts;
